import { createGlobalStyle } from "styled-components";
import inter100 from "../assets/fonts/Inter/static/Inter-Thin.ttf";
import inter200 from "../assets/fonts/Inter/static/Inter-Light.ttf";
import inter300 from "../assets/fonts/Inter/static/Inter-Regular.ttf";
import inter400 from "../assets/fonts/Inter/static/Inter-Medium.ttf";
import inter500 from "../assets/fonts/Inter/static/Inter-Bold.ttf";

interface StyledProps {
  isFirstEditor: boolean;
}

export const GlobalStyle = createGlobalStyle<StyledProps>`
  html{
    height: 100%;
  }
  body {
    background-color:#f9fafb;
	font-family:Inter-Regular !important;
    margin:0;
    padding:0;
	color: #333333
  }
  html body{
  height:100%;
  overflow: hidden;
  }
  #root {
    min-height:100%;
    min-width:100%;
    position:relative;
  }
  @font-face {
    font-family:'Inter-Thin';
	font-weight:100;
	src: local('Inter-Thin'), url(${inter100}) format('truetype');
  }
  @font-face {
    font-family:'Inter-Light';
	font-weight:300;
	src: local('Inter-Light'), url(${inter200}) format('truetype');
  }
  @font-face {
    font-family:'Inter-Regular';
	font-weight:400;
    src: local('Inter-Regular'), url(${inter300}) format('truetype');
  }
  @font-face {
	font-family:'Inter-Medium';
	font-weight:500;
	src: local('Inter-Medium'), url(${inter400}) format('truetype');
  }
  @font-face {
    font-family:'Inter-Bold';
	font-weight:700;
	src: local('Inter-Bold'), url(${inter500}) format('truetype');
  }
  .MuiMenu-root{
  z-index: 6000;
  }
  .ce-toolbar__content{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 60px !important;
    margin-right: auto !important;
  }
  .ce-block__content{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 70px !important;
    margin-right: auto !important;
  }
  .ce-block__content{
    max-width: unset;
  } 
  .codex-editor__redactor{
    padding-bottom: ${(props) =>
      props.isFirstEditor ? "300px !important" : "0px !important"}
  }
  .codex-editor{
    z-index: unset
  }
  .ce-example-popup__overlay{
    z-index: 2000
  }
  .ce-example-popup__popup{
    z-index: 3000
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 30px !important;
  }
  .ce-topic {
  font-size: 2.5em;
  }
  .ce-lesson {
  font-size: 2em;
  }
  .ce-point {
  font-size: 1.5em;
  }
  .ce-subPoint {
  font-size: 1.25em;
  }
  .swiper .swiper-button-next.swiper-button-disabled,
  .swiper .swiper-button-prev.swiper-button-disabled {
    opacity: 0;
  }
`;
// .ce-popover-item[data-item-name="topics"] {
//   display:  ${(props) =>
//     props.isTopicsDisabled ? "none !important" : "flex"};
// }
// .ce-popover-item[data-item-name="lessons"] {
//   display:  ${(props) =>
//     props.isLessonsDisabled ? "none !important" : "flex"};
// }
// .ce-popover-item[data-item-name="points"] {
//   display:  ${(props) =>
//     props.isPointsDisabled ? "none !important" : "flex"};
// }
// .ce-popover-item[data-item-name="subpoints"] {
//   display:  ${(props) =>
//     props.isSubPointsDisabled ? "none !important" : "flex"};
// }
