import React from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDefaultLayoutState,
  selectGlobalLanguage,
} from "app/pages/DefaultLayout/slice/selectors";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import { ILanguage } from "utils/type";
import { Spacer } from "app/components/Core";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DoneIcon from "@mui/icons-material/Done";
import Button from "@mui/material/Button";
import { useDefaultLayoutSlice } from "app/pages/DefaultLayout/slice";
import { languageOptions } from "utils/constants";

const TopBar = () => {
  const dispatch = useDispatch();
  const { actions } = useDefaultLayoutSlice();
  const globalLanguage = useSelector(selectGlobalLanguage);
  const loggedInUser = useSelector(selectDefaultLayoutState);
  const [anchorElLanguage, setAnchorElLanguage] =
    React.useState<null | HTMLElement>(null);
  const handleTranslate = (lang: ILanguage) => {
    dispatch(actions.setGlobalLanguage(lang));
    setAnchorElLanguage(null);
  };
  const handleLanguageOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElLanguage(event.currentTarget);
  };
  const handleLanguageClose = () => {
    setAnchorElLanguage(null);
  };
  const isLanguageOpen = Boolean(anchorElLanguage);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        padding: "1rem",
        borderBottom: "1px solid #e0e0e0",
        zIndex: 5001,
      }}
    >
      <Box
        sx={{ display: "flex", justifyContent: "space-between", columnGap: 2 }}
      >
        <Button
          onClick={handleLanguageOpen}
          endIcon={isLanguageOpen ? <ExpandLess /> : <ExpandMore />}
        >
          Language
        </Button>
        <Avatar src="/broken-image.jpg" />
      </Box>
      <Spacer mr={3} />
      <Menu
        id="basic-menu"
        anchorEl={anchorElLanguage}
        open={isLanguageOpen}
        onClose={handleLanguageClose}
      >
        {languageOptions.map((language) => (
          <MenuItem
            key={language.value}
            onClick={() => handleTranslate(language.value)}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 1,
              }}
            >
              <Box>{language.label}</Box>
              <Box sx={{ mb: "2px" }}>
                {language.value == globalLanguage && (
                  <DoneIcon fontSize="small" />
                )}
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default TopBar;
