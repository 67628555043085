/* App */

import { Helmet } from "react-helmet-async";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GlobalStyle } from "styles/global-styles";
import { ThemeProvider as MUIThemeProvider } from "@mui/material";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";
import { theme } from "styles/theme/themes";
import { theme as muiTheme } from "styles/theme/muiTheme";
import { IRoute } from "../utils/routes/types";
import { routes } from "../utils/routes";
import { PageNotFound } from "./pages/404";
import Authenticated from "./pages/Authenticated";
import PublicPages from "./pages/PublicPages";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useSelector } from "react-redux";
import { selectStyleProps } from "./pages/DefaultLayout/slice/selectors";

export function App() {
  const { i18n } = useTranslation();
  const { isFirstEditor } = useSelector(selectStyleProps);
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <MUIThemeProvider theme={muiTheme}>
          <DndProvider backend={HTML5Backend}>
            <BrowserRouter>
              <Helmet
                titleTemplate="%s - Course Enging"
                defaultTitle="Course Engine"
                htmlAttributes={{ lang: i18n.language }}
              >
                <meta name="description" content="A Website To Generate PDF" />
              </Helmet>
              <GlobalStyle isFirstEditor={isFirstEditor} />
              <Routes>
                <Route path="*" element={<PageNotFound />} />
                {routes.map((route: IRoute) => {
                  let body: any = null;
                  if (route.isAuthenticated) {
                    body = (
                      <Authenticated
                        path={route.path}
                        component={route.component}
                      />
                    );
                  } else {
                    body = <PublicPages route={route} />;
                  }
                  return (
                    <Route
                      key={uuidv4()}
                      path={route.path}
                      element={<>{body}</>}
                    />
                  );
                })}
              </Routes>
              <ToastContainer />
            </BrowserRouter>
          </DndProvider>
        </MUIThemeProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}
