import {
  IContentModel,
  IModuleRes,
  IModuleSchema,
  ITopicModel,
  ITopicRes,
} from "app/Model/course";
import { IElement, ParsedResponse } from "app/pages/Workplace/slice/type";
import { ILanguage } from "utils/type";
import { jwtDecode } from "jwt-decode";

import { type MutableRefObject, type RefCallback } from "react";

type MutableRefList<T> = Array<
  RefCallback<T> | MutableRefObject<T> | undefined | null
>;

export function mergeRefs<T>(...refs: MutableRefList<T>): RefCallback<T> {
  return (val: T) => {
    setRef(val, ...refs);
  };
}

export function setRef<T>(val: T, ...refs: MutableRefList<T>): void {
  refs.forEach((ref) => {
    if (!ref) return;
    if (typeof ref === "function") {
      ref(val);
    } else if (ref != null) {
      ref.current = val;
    }
  });
}

export const textFormater = (text: string, capitalizeFirst?: boolean) => {
  const formated = text?.replace(/([A-Z])/g, " $1").trim();
  let result = "";
  if (capitalizeFirst) {
    result = formated.charAt(0).toUpperCase() + formated.slice(1);
  }
  return result;
};

export const turnToHumanReadable = (date) => {
  let inputDate = new Date(date);
  const monthAbbreviations = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let month = monthAbbreviations[inputDate.getMonth()];
  let day = inputDate.getDate();
  let humanReadableDate = `${month} ${day}`;
  return humanReadableDate;
};

export function truncate(str, n = 13) {
  return str?.length > n ? str.substring(0, n) + "..." : str;
}

//if an endpoint to get entity is created this should be updated
export const findElementParents = (
  id: string,
  elementObject: Record<string, any>,
  parents: Array<string> = []
) => {
  for (const key in elementObject) {
    if (elementObject.hasOwnProperty(key)) {
      const current = elementObject[key];
      if (typeof current === "object" && current !== null) {
        if (key === id) {
          return [...parents, id];
        } else {
          let result;
          if (
            ["module", "topics", "lessons", "points", "sub_points"].includes(
              key
            )
          ) {
            result = findElementParents(id, current, [...parents]);
          } else {
            result = findElementParents(id, current, [...parents, key]);
          }
          if (result) {
            return result;
          }
        }
      }
    }
  }
  return null;
};

export const addTimestampToUrl = (url: string) => {
  if (!url || url === "NA") return url;
  const separator = url.includes("?") ? "&" : "?";
  return `${url}${separator}t=${new Date().getTime()}`;
};

export function mapApiResponseToModuleSchema(
  apiResponse: IModuleRes
): IModuleSchema {
  const {
    module_id,
    module_sequence_number,
    module_image_url,
    module_title,
    modified_date,
    pdf_url,
    ppt_url,
    topics,
  } = apiResponse;

  const moduleSchema: IModuleSchema = {
    id: module_id,
    sequence_num: module_sequence_number,
    title: module_title,
    image_url: module_image_url,
    pdf_url,
    ppt_url,
    modified_date: modified_date,
    topics: {},
  };

  // Map topics
  Object.keys(topics || {}).forEach((topicId) => {
    if (topics) {
      const topic = topics[topicId];
      if (moduleSchema?.["topics"]) {
        moduleSchema.topics[topicId] = {
          sequence_number: topic.sequence_number,
          title: topic.title,
          img_url: topic.img_url,
          ppt_url: topic.ppt_url,
          modified_date: topic.modified_date,
          lessons: {},
          contents: topic.contents,
        };
      }
      // Map lessons
      Object.keys(topic.lessons).forEach((lessonId) => {
        const lesson = topic.lessons[lessonId];
        if (moduleSchema?.["topics"]) {
          moduleSchema.topics[topicId].lessons[lessonId] = {
            sequence_number: lesson.sequence_number,
            title: lesson.title,
            modified_date: lesson.modified_date,
            points: {},
            contents: lesson.contents,
          };
        }
        // Map points
        Object.keys(lesson.points).forEach((pointId) => {
          const point = lesson.points[pointId];
          if (moduleSchema?.["topics"]) {
            moduleSchema.topics[topicId].lessons[lessonId].points[pointId] = {
              sequence_number: point.sequence_number,
              title: point.title,
              modified_date: point.modified_date,
              sub_points: {},
              contents: point.contents,
            };
          }

          // Map sub_points
          Object.keys(point.sub_points).forEach((subPointId) => {
            const subPoint = point.sub_points[subPointId];
            if (moduleSchema?.["topics"]) {
              moduleSchema.topics[topicId].lessons[lessonId].points[
                pointId
              ].sub_points[subPointId] = {
                sequence_number: subPoint.sequence_number,
                title: subPoint.title,
                modified_date: subPoint.modified_date,
                contents: subPoint.contents,
              };
            }
          });
        });
      });
    }
  });
  return moduleSchema;
}

const findElementById = (
  id: string,
  topics: Record<string, ITopicRes>
): IElement | undefined => {
  for (const topicId in topics) {
    if (topics.hasOwnProperty(topicId)) {
      const topic = topics[topicId];
      if (topicId === id) {
        return { element_name: topic.title, element_id: topicId };
      }
      if (topic.lessons) {
        for (const lessonId in topic.lessons) {
          if (topic.lessons.hasOwnProperty(lessonId)) {
            const lesson = topic.lessons[lessonId];
            if (lessonId === id) {
              return { element_name: lesson.title, element_id: lessonId };
            }
            if (lesson.points) {
              for (const pointId in lesson.points) {
                if (lesson.points.hasOwnProperty(pointId)) {
                  const point = lesson.points[pointId];
                  if (pointId === id) {
                    return {
                      element_name: point.title,
                      element_id: pointId,
                    };
                  }
                  if (point.sub_points) {
                    for (const subPointId in point.sub_points) {
                      if (point.sub_points.hasOwnProperty(subPointId)) {
                        const subPoint = point.sub_points[subPointId];
                        if (subPointId === id) {
                          return {
                            element_name: subPoint.title,
                            element_id: subPointId,
                          };
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return undefined;
};

export function parseLockRes({ response, data }): ParsedResponse {
  const { sections_without_contents, sections_without_images } = response;
  const { module } = data;

  const sectionsWithoutContentsParsed: ParsedResponse = {
    sections_without_contents: {
      topics: [],
      lessons: [],
      points: [],
      sub_points: [],
    },
    sections_without_images: {
      modules: [],
      topics: [],
      lessons: [],
      points: [],
      sub_points: [],
    },
  };

  sections_without_contents?.forEach((id) => {
    const element = findElementById(id, module.topics);
    if (element) {
      switch (id[0]) {
        case "T":
          sectionsWithoutContentsParsed.sections_without_contents.topics.push(
            element
          );
          break;
        case "L":
          sectionsWithoutContentsParsed.sections_without_contents.lessons.push(
            element
          );
          break;
        case "P":
          sectionsWithoutContentsParsed.sections_without_contents.points.push(
            element
          );
          break;
        case "S":
          sectionsWithoutContentsParsed.sections_without_contents.sub_points.push(
            element
          );
          break;
        default:
          break;
      }
    }
  });

  sections_without_images?.forEach((id) => {
    const element = findElementById(id, module.topics);
    if (element) {
      switch (id[0]) {
        case "T":
          sectionsWithoutContentsParsed.sections_without_images.topics.push(
            element
          );
          break;
        default:
          break;
      }
    }
    if (module?.["module_id"] == id) {
      sectionsWithoutContentsParsed.sections_without_images.modules.push({
        element_name: module["en_title"],
        element_id: id,
      });
    }
  });

  return sectionsWithoutContentsParsed;
}

export function findElementsWithMissingImageUrl(moduleObj) {
  const elementIds: Array<string> = [];

  if (moduleObj.module_image_url === "NA") {
    elementIds.push(moduleObj.module_id);
  }

  for (const topicId in moduleObj.topics) {
    const topic = moduleObj.topics[topicId];
    if (topic.img_url === "NA") {
      elementIds.push(topicId);
    }
  }

  return elementIds;
}
export const localstorageGetItem = (itemName: string) => {
  if (typeof itemName === "string") {
    const localObject = JSON.parse(localStorage.getItem("user") || "{}");
    if (localObject && Object.keys(localObject).includes(itemName)) {
      return localObject[`${itemName}`];
    }
    // if no value found with itemName key return empty string
    return "";
  }
  return "";
};

const getLanguageKey = (language) => {
  switch (language) {
    case ILanguage.de:
      return "de_phrase";
    case ILanguage.es:
      return "es_phrase";
    case ILanguage.fr:
      return "fr_phrase";
    case ILanguage.it:
      return "it_phrase";
    case ILanguage.nl:
      return "nl_phrase";
    default:
      throw new Error(`Unsupported language: ${language}`);
  }
};

export const extractPhrases = (glossaries, targetLanguage) => {
  const languageKey = getLanguageKey(targetLanguage);
  return glossaries
    .filter((glossary) => glossary[languageKey])
    .map((glossary) => ({
      id: glossary.glossary_id,
      en_phrase: glossary.en_phrase,
      target_phrase: glossary[languageKey],
    }));
};

export const extractPhrasesByIds = (glossaries, ids, targetLanguage) => {
  const languageKey = getLanguageKey(targetLanguage);

  return glossaries.reduce((acc, glossary) => {
    if (ids.includes(glossary.glossary_id) && glossary[languageKey]) {
      acc[glossary.en_phrase] = glossary[languageKey];
    }
    return acc;
  }, {});
};

export const decodeToken = (token) => {
  try {
    const decoded = jwtDecode(token);
    return decoded.exp;
  } catch (error) {
    console.error("Invalid token", error);
    return null;
  }
};

export function debounce(func, wait) {
  let timeoutId;

  return function (...args) {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func(...args);
    }, wait);
  };
}

export const throttle = (func, delay) => {
  let timeoutId;
  return function (...args) {
    if (!timeoutId) {
      timeoutId = setTimeout(() => {
        func(...args);
        timeoutId = null;
      }, delay);
    }
  };
};

export function hasChanges(data: { [key: string]: any }): boolean {
  for (const key in data) {
    if (
      data[key].changed.length > 0 ||
      data[key].created.length > 0 ||
      data[key].deleted.length > 0
    ) {
      return true;
    }
  }
  return false;
}
