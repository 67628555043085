import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Tooltip from "@mui/material/Tooltip";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { IListMenu } from "./types";
import { routes } from "utils/routes";
import { IRoute } from "utils/routes/types";
import { ROUTES } from "utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { textFormater } from "helper/helperFunctions";
import { useDispatch, useSelector } from "react-redux";
import { useDefaultLayoutSlice } from "app/pages/DefaultLayout/slice";
import LogoutIcon from "@mui/icons-material/Logout";
import { clearLocalStorage } from "utils/localStorage";
import { useTheme } from "@mui/material";
import { selectDefaultLayoutState } from "app/pages/DefaultLayout/slice/selectors";

const drawerWidth = 240;

const menuRoutes = [
  ROUTES.home,
  ROUTES.modules,
  ROUTES.settings,
  ROUTES.course,
  ROUTES.topics,
  ROUTES.courses,
  ROUTES.quizzes,
  ROUTES.importPage,
  ROUTES.jobs,
  ROUTES.glossary,
];

export default function SideBar() {
  const theme = useTheme();
  const navigate = useNavigate();
  const defaultLayout = useSelector(selectDefaultLayoutState);
  const dispatch = useDispatch();
  const { actions } = useDefaultLayoutSlice();
  const location = useLocation();
  const [currentlySelected, setCurrentlySelected] = React.useState("");
  const [listMenu, setListMenu] = React.useState<IListMenu[]>([]);

  React.useEffect(() => {
    const list: IListMenu[] = routes.reduce(
      (accumulator: IListMenu[], currentValue: IRoute) => {
        if (menuRoutes.includes(currentValue.path as ROUTES)) {
          if (
            (currentValue.path == "/import" || currentValue.path == "/jobs") &&
            defaultLayout?.user?.org_name != "Scholistico"
          ) {
          } else {
            if (
              currentValue.path == "/import" &&
              (defaultLayout?.user?.role == "new" ||
                defaultLayout?.user?.role == "viewer")
            ) {
            } else {
              accumulator.push({
                route: currentValue.path,
                icon: currentValue?.icon,
                label: currentValue?.label,
              });
            }
          }
        }
        return accumulator;
      },
      []
    );
    setListMenu(list);
  }, [defaultLayout?.isSigningIn]);

  React.useEffect(() => {
    listMenu?.map((v) => {
      if (v.route == location.pathname) {
        setCurrentlySelected(v?.label || "");
      }
    });
  }, [location.pathname, listMenu?.length]);

  const handleRoute = (path: string) => {
    if (path === ROUTES.settings) {
      navigate("/settings");
    } else {
      navigate(path);
    }
  };

  return (
    <>
      <CssBaseline />
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            borderColor: "#e4e4e5",
            boxShadow: "2px 0 10px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <Typography
          sx={{
            color: theme.palette.primary.main,
            marginBottom: "30px",
            fontSize: "1.5rem",
            textAlign: "center",
            paddingTop: 3,
            cursor: "pointer",
          }}
          variant="h1"
          onClick={() => navigate("/home")}
        >
          Course Engine AI
        </Typography>
        <Box sx={{ overflow: "auto", poisition: "relative" }}>
          <List sx={{ padding: 2 }}>
            {listMenu.map(({ icon: Icon, ...value }: IListMenu) => (
              <Tooltip
                placement="right"
                key={value.route}
                title={textFormater(value.label as string, true)}
              >
                <ListItem
                  sx={{
                    borderRadius: "0px !important",
                    transition:
                      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    "&:hover": {
                      boxShadow:
                        "0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                      backgroundColor: "rgba(52, 152, 219, 0.1)",
                      cursor: "pointer",
                      opacity: "50%",
                    },
                    "&.MuiListItem-root": {
                      paddingLeft: "4px",
                    },
                    boxShadow: `${
                      currentlySelected == value.label
                        ? "0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 1px 5px 0px rgba(0, 0, 0, 0.12)"
                        : "unset"
                    }`,
                    backgroundColor: `${
                      currentlySelected == value.label
                        ? "rgba(52, 152, 219, 0.1)"
                        : "unset"
                    }`,
                  }}
                  onClick={() => {
                    handleRoute(value.route);
                  }}
                >
                  {Icon ? <Icon /> : null}
                  <ListItemText
                    sx={{
                      paddingLeft: 1,
                      fontWeight: "unset",
                    }}
                    primary={textFormater(value.label as string, true)}
                  />
                </ListItem>
              </Tooltip>
            ))}
          </List>
          <Divider />
          <List sx={{ padding: 2 }}>
            <Tooltip
              placement="right"
              title={textFormater("Logout" as string, true)}
            >
              <ListItem
                sx={{
                  borderRadius: "0px !important",
                  transition:
                    "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  "&:hover": {
                    boxShadow:
                      "0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                    backgroundColor: "rgba(52, 152, 219, 0.1)",
                    cursor: "pointer",
                    opacity: "50%",
                  },
                  "&.MuiListItem-root": {
                    paddingLeft: "4px",
                  },
                  boxShadow: `${
                    currentlySelected == "Logout"
                      ? "0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 1px 5px 0px rgba(0, 0, 0, 0.12)"
                      : "unset"
                  }`,
                  backgroundColor: `${
                    currentlySelected == "Logout"
                      ? "rgba(52, 152, 219, 0.1)"
                      : "unset"
                  }`,
                }}
                onClick={() => {
                  dispatch(actions.clearUser());
                  clearLocalStorage();
                  navigate("/login");
                }}
              >
                <LogoutIcon />
                <ListItemText
                  sx={{
                    paddingLeft: 1,
                  }}
                  primary={textFormater("Logout" as string, true)}
                />
              </ListItem>
            </Tooltip>
          </List>
        </Box>
      </Drawer>
    </>
  );
}
