import { PayloadAction } from "@reduxjs/toolkit";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ISettingState, IUpdateOrganization } from "./types";
import { IProfileUpdate } from "../type";
import { SettingSagaWatcher } from "./saga";
import { IUserModel } from "app/Model/user";
import { IOrganization } from "app/pages/DefaultLayout/slice/type";

export const initialState: ISettingState = {
  isSettingError: false,
  isSettingLoading: false,
  roles: [],
  users: [],
};
const SettingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    listUsers(state) {
      state.isSettingLoading = true;
    },
    setUsers(state, action: PayloadAction<IUserModel[]>) {
      state.users = action.payload;
    },
    updateUser(state, action: PayloadAction<IProfileUpdate>) {
      state.isSettingLoading = true;
    },
    deleteUser(state, action: PayloadAction<IUserModel>) {
      state.isSettingLoading = true;
    },
    setIsUpdating(state, action: PayloadAction<boolean>) {
      state.isSettingLoading = action.payload;
    },
    getRoles(state) {
      state.isSettingLoading = true;
    },
    setRoles(state, action: PayloadAction<{ id: string; name: string }[]>) {
      state.roles = action.payload;
    },
    inviteUser(
      state,
      action: PayloadAction<{ emails: string[]; role: string }>
    ) {
      state.isSettingLoading = true;
    },
    setIsSettingError(state, action: PayloadAction<boolean>) {
      state.isSettingError = action.payload;
    },
    updateOrganization(state, action: PayloadAction<IUpdateOrganization>) {
      state.isSettingLoading;
    },
  },
});

export const { actions: settingActions } = SettingSlice;
export const useSettingSlice = () => {
  useInjectReducer({ key: SettingSlice.name, reducer: SettingSlice.reducer });
  useInjectSaga({ key: SettingSlice.name, saga: SettingSagaWatcher });
  return { actions: SettingSlice.actions };
};
export const modulesReducers = SettingSlice.reducer;
