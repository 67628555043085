import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton,
  useTheme,
} from "@mui/material";
import {
  Add,
  Edit,
  Delete,
  Check,
  Close,
  InfoOutlined,
} from "@mui/icons-material";
import { selectAllGlossaries } from "./slice/selector";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { theme as styledTheme } from "styles/theme/themes";
import Typography from "@mui/material/Typography";
import { useGlossarySlice } from "./slice";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  selectGlobalLanguage,
  selectUser,
} from "../DefaultLayout/slice/selectors";

// Dynamic validation schema
const getValidationSchema = (selectedLanguage: string) => {
  if (selectedLanguage) {
    const _lang = selectedLanguage.toLowerCase();
    const baseSchema = {
      en_phrase: Yup.string()
        .notOneOf(["None"], "Value 'None' is not allowed")
        .matches(/^[^'"]*$/, "Quotes are not allowed"),
      de_phrase: Yup.string()
        .notOneOf(["None"], "Value 'None' is not allowed")
        .matches(/^[^'"]*$/, "Quotes are not allowed"),
      es_phrase: Yup.string()
        .notOneOf(["None"], "Value 'None' is not allowed")
        .matches(/^[^'"]*$/, "Quotes are not allowed"),
      fr_phrase: Yup.string()
        .notOneOf(["None"], "Value 'None' is not allowed")
        .matches(/^[^'"]*$/, "Quotes are not allowed"),
      nl_phrase: Yup.string()
        .notOneOf(["None"], "Value 'None' is not allowed")
        .matches(/^[^'"]*$/, "Quotes are not allowed"),
      it_phrase: Yup.string()
        .notOneOf(["None"], "Value 'None' is not allowed")
        .matches(/^[^'"]*$/, "Quotes are not allowed"),
    };

    // Make the selected language field required
    baseSchema[`${_lang}_phrase`] =
      baseSchema[`${_lang}_phrase`].required("Required");

    return Yup.object().shape(baseSchema);
  }
};

const Glossary = () => {
  const loggedInUser = useSelector(selectUser);
  const role = loggedInUser?.role;
  const dispatch = useDispatch();
  const glossaries = useSelector(selectAllGlossaries);
  const [editIdx, setEditIdx] = useState(null);
  const { actions } = useGlossarySlice();
  const theme = useTheme();
  const globalLanguage = useSelector(selectGlobalLanguage);

  useEffect(() => {
    dispatch(actions.getGlossaries());
  }, []);

  const handleEditRow = (index) => {
    setEditIdx(index);
  };

  const handleCancelEdit = () => {
    setEditIdx(null);
  };

  const handleDeleteRow = (index) => {
    dispatch(actions.deleteGlossary(glossaries[index].glossary_id));
  };

  const handleAddRow = (values, { resetForm }) => {
    const filteredValues: any = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => value)
    );
    dispatch(actions.createGlossary({ ...filteredValues }));
    resetForm();
  };

  const handleSaveRow = (values) => {
    const { creation_date, modified_date, ...filteredValues } = values;
    dispatch(actions.createGlossary(filteredValues));
    setEditIdx(null);
  };

  return (
    <Grid
      container
      justifyContent={"center"}
      sx={{ width: "100%", p: 3 }}
      rowSpacing={4}
    >
      <Grid item xs={12}>
        <Typography
          gutterBottom
          sx={{
            fontSize: "24px",
            fontWeight: 600,
            color: styledTheme.colors.primary.main,
          }}
        >
          Glossaries
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, mb: 2, backgroundColor: theme.palette.grey[100] }}>
          <Typography variant="h6" gutterBottom>
            <InfoOutlined sx={{ verticalAlign: "middle", mr: 1 }} />
            How to Use the Glossary
          </Typography>
          <Typography variant="body2" paragraph>
            The glossary allows you to manage translations for various phrases
            across different languages. Here's how to use it:
          </Typography>
          <ol>
            <li>
              <Typography variant="body2">
                <strong>Adding a new entry:</strong> Fill in the English phrase
                and its translations in other languages. Click "Add" to save.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <strong>Editing an entry:</strong> Click the edit icon, make
                your changes, and click the check mark to save.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <strong>Deleting an entry:</strong> Click the delete icon to
                remove a glossary entry.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <strong>Important:</strong> Ensure all translations are accurate
                and consistent across languages.
              </Typography>
            </li>
          </ol>
        </Paper>
      </Grid>
      <Grid
        sx={{
          overflow: "auto",
          "-ms-overflow-style": "none",
          maxHeight: "70vh",
        }}
        item
        xs={12}
      >
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
                <TableCell
                  sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                >
                  English
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                >
                  German
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                >
                  Spanish
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                >
                  French
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                >
                  Dutch
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                >
                  Italian
                </TableCell>
                {(role == "editor" ||
                  role == "admin" ||
                  role == "super_user") && <TableCell>Actions</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {(role == "editor" ||
                role == "admin" ||
                role == "super_user") && (
                <Formik
                  initialValues={{
                    en_phrase: "",
                    de_phrase: "",
                    es_phrase: "",
                    fr_phrase: "",
                    nl_phrase: "",
                    it_phrase: "",
                  }}
                  validationSchema={getValidationSchema(globalLanguage!)}
                  onSubmit={handleAddRow}
                >
                  {({ values, handleChange, handleBlur, handleSubmit }) => (
                    <TableRow>
                      <TableCell>
                        <Field
                          as={TextField}
                          name="en_phrase"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.en_phrase}
                        />
                        <ErrorMessage name="en_phrase" component="div" />
                      </TableCell>
                      <TableCell>
                        <Field
                          as={TextField}
                          name="de_phrase"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.de_phrase}
                        />
                        <ErrorMessage name="de_phrase" component="div" />
                      </TableCell>
                      <TableCell>
                        <Field
                          as={TextField}
                          name="es_phrase"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.es_phrase}
                        />
                        <ErrorMessage name="es_phrase" component="div" />
                      </TableCell>
                      <TableCell>
                        <Field
                          as={TextField}
                          name="fr_phrase"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.fr_phrase}
                        />
                        <ErrorMessage name="fr_phrase" component="div" />
                      </TableCell>
                      <TableCell>
                        <Field
                          as={TextField}
                          name="nl_phrase"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.nl_phrase}
                        />
                        <ErrorMessage name="nl_phrase" component="div" />
                      </TableCell>
                      <TableCell>
                        <Field
                          as={TextField}
                          name="it_phrase"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.it_phrase}
                        />
                        <ErrorMessage name="it_phrase" component="div" />
                      </TableCell>

                      <TableCell>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            handleSubmit();
                          }}
                          startIcon={<Add />}
                        >
                          Add
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                </Formik>
              )}
              {glossaries.map((glossary, index) => (
                <Formik
                  key={glossary.glossary_id}
                  initialValues={glossary}
                  validate={() => {}}
                  onSubmit={handleSaveRow}
                >
                  {({ values, handleChange, handleBlur, handleSubmit }) => (
                    <TableRow>
                      <TableCell>
                        {editIdx === index ? (
                          <Field
                            as={TextField}
                            name="en_phrase"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.en_phrase}
                          />
                        ) : (
                          glossary.en_phrase
                        )}
                        {editIdx === index && (
                          <ErrorMessage name="en_phrase" component="div" />
                        )}
                      </TableCell>
                      <TableCell>
                        {editIdx === index ? (
                          <Field
                            as={TextField}
                            name="de_phrase"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.de_phrase}
                          />
                        ) : (
                          glossary.de_phrase
                        )}
                        {editIdx === index && (
                          <ErrorMessage name="de_phrase" component="div" />
                        )}
                      </TableCell>
                      <TableCell>
                        {editIdx === index ? (
                          <Field
                            as={TextField}
                            name="es_phrase"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.es_phrase}
                          />
                        ) : (
                          glossary.es_phrase
                        )}
                        {editIdx === index && (
                          <ErrorMessage name="es_phrase" component="div" />
                        )}
                      </TableCell>
                      <TableCell>
                        {editIdx === index ? (
                          <Field
                            as={TextField}
                            name="fr_phrase"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.fr_phrase}
                          />
                        ) : (
                          glossary.fr_phrase
                        )}
                        {editIdx === index && (
                          <ErrorMessage name="fr_phrase" component="div" />
                        )}
                      </TableCell>
                      <TableCell>
                        {editIdx === index ? (
                          <Field
                            as={TextField}
                            name="nl_phrase"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.nl_phrase}
                          />
                        ) : (
                          glossary.nl_phrase
                        )}
                        {editIdx === index && (
                          <ErrorMessage name="nl_phrase" component="div" />
                        )}
                      </TableCell>
                      <TableCell>
                        {editIdx === index ? (
                          <Field
                            as={TextField}
                            name="it_phrase"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.it_phrase}
                          />
                        ) : (
                          glossary.it_phrase
                        )}
                        {editIdx === index && (
                          <ErrorMessage name="it_phrase" component="div" />
                        )}
                      </TableCell>
                      {(role == "editor" ||
                        role == "admin" ||
                        role == "super_user") && (
                        <TableCell>
                          {editIdx === index ? (
                            <Stack sx={{ width: "100%" }} direction="row">
                              <IconButton
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleSubmit();
                                }}
                                color="primary"
                              >
                                <Check />
                              </IconButton>
                              <IconButton
                                onClick={() => handleCancelEdit()}
                                color="error"
                              >
                                <Close />
                              </IconButton>
                            </Stack>
                          ) : (
                            <Stack sx={{ width: "100%" }} direction="row">
                              <IconButton
                                size="small"
                                onClick={() => handleEditRow(index)}
                                color="primary"
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                onClick={() => handleDeleteRow(index)}
                                color="error"
                              >
                                <Delete />
                              </IconButton>
                            </Stack>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  )}
                </Formik>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default Glossary;
