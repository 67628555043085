import List from "@editorjs/list";
import Paragraph from "@editorjs/paragraph";
import Table from "@editorjs/table";
import Topics from "@kalebu2468/editorjs-topic";
import Lessons from "@kalebu2468/editor-lessons";
import Points from "@kalebu2468/editorjs-points";
import SubPoints from "@kalebu2468/editor-subpoints";
import Comment from "@kalebu2468/editorjs-comment";
import RenderItem from "app/components/Comment";
export const EDITOR_JS_TOOLS = {
  list: {
    class: List,
    config: {
      defaultStyle: "unordered",
    },
    inlineToolbar: ["italic", "bold"],
  },
  paragraph: {
    class: Paragraph,
    inlineToolbar: ["italic", "comment", "bold"],
  },
  table: {
    class: Table,
    inlineToolbar: ["italic", "bold"],
  },
  topics: {
    class: Topics,
    config: {
      placeholder: "Enter a Topic",
      levels: [1],
      defaultLevel: 1,
    },
    inlineToolbar: ["italic", "bold"],
  },
  lessons: {
    class: Lessons,
    config: {
      placeholder: "Enter a Lesson",
      levels: [2],
      defaultLevel: 2,
    },
    inlineToolbar: ["italic", "bold"],
  },
  points: {
    class: Points,
    config: {
      placeholder: "Enter a Point",
      levels: [3],
      defaultLevel: 3,
    },
    inlineToolbar: ["italic", "bold"],
  },
  subpoints: {
    class: SubPoints,
    config: {
      placeholder: "Enter a Sub-Point",
      levels: [4],
      defaultLevel: 4,
    },
    inlineToolbar: ["italic", "bold"],
  },
  comment: {
    class: Comment,
    inlineToolbar: true,
    config: {
      markerColor: "#00FF00", // optional
      activeColor: "green", // optional
      renderBody: ({
        commentBlockId,
        blockId,
        onClose,
        addCommentBlockData,
        removeBlockComments,
        state,
      }) => {
        return RenderItem({
          onClose,
          blockId,
          commentBlockId,
          addCommentBlockData,
          removeBlockComments,
          state,
        });
      },
    },
  },
};
