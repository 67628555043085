import * as React from "react";
import { signal } from "@preact-signals/safe-react";
import { withTrackSignals } from "@preact-signals/safe-react/manual";
import { v4 as uuidV4 } from "uuid";
import { CommentBlockData, RenderBody } from "@kalebu2468/editorjs-comment";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import makeCall from "app/API/makeCalls";
import routes from "app/API/api.routes";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Stack from "@mui/material/Stack";
import ClearIcon from "@mui/icons-material/Clear";

interface Comment {
  commentBlockId: string;
  blockId: string;
  content: string;
  //... your custom fields
}

const comments = signal<Comment[]>([]);

const commentSignal = signal<Comment>(Object());

const RenderItem = ({
  commentBlockId,
  blockId,
  onClose,
  addCommentBlockData,
  removeBlockComments,
  state,
}: RenderBody) => {
  commentSignal.value = {
    ...Object(),
    commentBlockId,
    blockId,
  };

  const addContractCommentApi = async (commentData: Comment) => {
    // Define the API route for adding comments
    try {
      // Prepare the request body
      const requestBody = {
        content_id: commentData.blockId, // Use blockId as content_id
        content: commentData.content, // Actual comment content
        // Add any other fields if necessary
      };

      // Make the API call using makeCall
      const response = await makeCall({
        route: routes.notes.create,
        method: "POST",
        body: requestBody,
        isSecureRoute: true, // Update according to your API setup
      });

      // Handle response if necessary
      return response; // Assuming response is in JSON format
    } catch (error) {
      // Handle errors
      console.error("Error adding comment:", error);
      throw error; // Rethrow the error for handling at a higher level
    }
  };

  const getContractCommentByIdApi = async (commentBlockId) => {
    // Define the API route for fetching contents

    try {
      // Make the API call to fetch contents
      const response = await makeCall({
        route: routes.content.getContent,
        method: "GET",
        query: { contentId: blockId }, // Include commentBlockId in the query params
        isSecureRoute: true, // Update according to your API setup
      });

      // Filter notes based on commentBlockId from the response
      const filteredNotes = response.notes.filter(
        (note) => note.commentBlockId === commentBlockId
      );

      // Resolve with the filtered notes
      return filteredNotes;
    } catch (error) {
      // Handle errors
      console.error("Error fetching comments:", error);
      throw error; // Rethrow the error for handling at a higher level
    }
  };

  const data = (ev) => {
    commentSignal.value = { ...commentSignal.value, content: ev.target.value };
  };

  //save comment to DB
  const saveComment = () => {
    const button = document.getElementById(
      "commentButton"
    ) as HTMLButtonElement | null;
    if (button) {
      button.disabled = true;
    }

    addContractCommentApi({
      ...commentSignal.value,
    })
      .then((respo: any) => {
        if (respo.code == 0) {
          const note: any = Object.keys(respo.notes)?.reduce((acc, curr) => {
            if (respo.notes[curr].content == commentSignal.value.content) {
              acc = {
                commentBlockId: curr,
              };
            }
            return acc;
          }, {});
          const data = {
            id: note?.commentBlockId,
            count: Object.keys(respo.notes).length,
          };
          // if the data is saved to the database  then call the setCommentBlockData method
          // for every new comment section this should be called, it will highlight the selected section with marker and also add the required data to the block.
          // this can be called only once for a new comment section
          if (addCommentBlockData) {
            addCommentBlockData(data);
          }
          const _comments: Comment[] = Object.keys(respo.notes).reduce(
            (acc: Comment[], key: string) => {
              const comment: Comment = {
                commentBlockId: key,
                blockId: key.substring(1, key.length - 1), // Assuming blockId is derived from the key
                content: respo.notes[key].text, // Assuming 'text' is the content
                // Add your custom fields if needed
              };
              acc.push(comment);
              return acc;
            },
            []
          );
          console.log("------------>Respon", _comments);
          comments.value = _comments;
        }
      })
      .finally(() => {
        // Enable the button after the API call is completed
        if (button) {
          button.disabled = false;
        }
      });
  };

  //The commentBlockId for the current  comment section is available  to be use to query the db, depending on your logic
  const getComments = () => {
    if (!commentBlockId) {
      comments.value = [];
      return;
    }
    getContractCommentByIdApi(commentBlockId)
      .then((respon: any) => {
        if (respon.length > 0) {
          addCommentBlockData({
            id: respon[0].commentBlockId,
            count: respon.length,
          });
          comments.value = respon;
        }
      })
      .catch((err) => {
        comments.value = [];
        console.log(err);
      });
  };
  getComments();

  class ShowComments extends React.Component<
    {},
    { editingIndex: number | null }
  > {
    constructor(props) {
      super(props);
      this.state = {
        editingIndex: null,
      };
    }
    handleDelete = (index) => {
      removeBlockComments();
    };
    handleEditClick = (index) => {
      this.setState({ editingIndex: index });
    };

    handleSaveClick = () => {
      // Handle saving the edited comment
      // For simplicity, I'm just setting editingIndex back to null
      this.setState({ editingIndex: null });
    };

    render() {
      const { editingIndex } = this.state;

      return (
        <>
          {[{ content: "first comment" }].map((comment, index) => (
            <Box
              key={index}
              sx={{
                background: "#edf2fa",
                padding: "16px 16px 8px",
                paddingBottom: "12px",
                borderBottom: "none",
                height: "70px",
                borderRadius: "4px",
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
                margin: 1,
              }}
            >
              {editingIndex === index ? (
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      padding: "8px 14px",
                    },
                  }}
                  placeholder="@Comment"
                  fullWidth
                  id="commentInput"
                  type="text"
                  value={comment.content}
                  onChange={data}
                  variant="outlined"
                  autoComplete="off"
                />
              ) : (
                <Typography>{comment.content}</Typography>
              )}
              <Stack direction="row" spacing={1}>
                {editingIndex === index ? (
                  <Button onClick={() => this.handleSaveClick()}>Save</Button>
                ) : (
                  <IconButton
                    onClick={() => this.handleEditClick(index)}
                    size="small"
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                )}
                <IconButton
                  aria-label="delete"
                  onClick={() => this.handleDelete(index)}
                  size="small"
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Stack>
            </Box>
          ))}
        </>
      );
    }
  }

  const closeEl = () => {
    if (!onClose) return;
    onClose();
  };

  return (
    <Paper
      elevation={1}
      square={false}
      sx={{
        boxSizing: "border-box",
        position: "fixed",
        right: "8px",
        top: "240px",
        zIndex: "6000 !important",
        display: "flex",
        flexDirection: "column",
        borderRadius: "12px",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{ padding: 1, width: "100%", minWidth: "450px" }}
      >
        <Grid
          container
          item
          xs={12}
          sx={{
            justifyContent: "flex-end",
            padding: "4px",
            alignItems: "center",
          }}
        >
          <IconButton onClick={closeEl}>
            <ClearIcon />
          </IconButton>
        </Grid>
        {!state && (
          <>
            <Grid item xs={12}>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-input": {
                    padding: "8px 14px",
                  },
                }}
                placeholder="@Comment"
                fullWidth
                id="commentInput"
                type="text"
                value={commentSignal.value.content}
                onChange={data}
                variant="outlined"
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={6} />
              <Grid
                item
                xs={6}
                container
                sx={{ justifyContent: "space-between" }}
              >
                <Button onClick={() => closeEl()}>Cancel</Button>
                <Button
                  id="commentButton"
                  onClick={() => saveComment()}
                  variant="contained"
                >
                  Comment
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <div id="showComments">
        <ShowComments />
      </div>
    </Paper>
  );
};

export default RenderItem;
