import routes from "app/API/api.routes";
import makeCall from "app/API/makeCalls";
import { eventChannel, END } from "redux-saga";

export function createFileUploaderChannel({
  data,
  id,
  isImage,
}: {
  data: FormData;
  id: string;
  isImage: boolean;
}) {
  let emit: any;

  const channel = eventChannel((emitter) => {
    emit = emitter;
    return () => {};
  });

  const onUpload = ({ total, loaded, lengthComputable }: ProgressEvent) => {
    let percentage;
    if (lengthComputable) {
      percentage = Math.round((loaded / total) * 100);
      console.log("====================>", percentage);
      emit(percentage);
    }
    if (percentage === 100) emit(END);
  };

  const uploadPromise = makeCall({
    body: data,
    route: isImage
      ? routes.fileRelated.uploadImage
      : routes.fileRelated.uploadPPT,
    isSecureRoute: true,
    method: "PUT",
    query: { id },
    onUploadProgress: onUpload,
  });

  return [uploadPromise, channel];
}
