import * as yup from "yup";

const parseObjectForValidation = (obj: Array<Record<string, any>>) => {
  const newObj = obj.reduce((acc, curr) => {
    if (curr.type === "paragraph") {
      acc.paragraphs = [...(acc.paragraphs || []), curr.data.text];
    } else if (curr.type === "list") {
      acc.lists = [
        ...(acc.lists || []),
        ...(curr.data.items.length ? curr.data.items : [""]),
      ];
    }
    return acc;
  }, {});
  if (newObj?.paragraphs?.length) {
    newObj["isParagraph"] = true;
  } else {
    newObj["isParagraph"] = false;
  }
  if (newObj?.lists?.length) {
    newObj["isList"] = true;
  } else {
    newObj["isList"] = false;
  }
  return newObj;
};

export async function validateObject(
  objectToValidate: Array<Record<string, any>>
) {
  const result: any = { success: true, errors: [] };
  try {
    const parsedObj = parseObjectForValidation(objectToValidate);
    console.log("===============================>obj", objectToValidate);
    const notJustNumbers = (value: any) => {
      return !/^\d+$/.test(value);
    };
    const schema = yup.object().shape({
      isParagraph: yup.boolean(),
      isList: yup.boolean(),
      paragraphs: yup.array().when("isParagraph", {
        is: true,
        then: (schema) =>
          yup
            .array()
            .of(
              yup
                .string()
                .min(4, "Paragraph must be at least 4 characters")
                .test(
                  "notJustNumbers",
                  "Paragraph cannot be just numbers",
                  notJustNumbers
                )
                .required("Paragraph cannot be empty")
            ),
      }),
      lists: yup.array().when("isList", {
        is: true,
        then: (schema) =>
          yup
            .array()
            .of(
              yup
                .string()
                .min(4, "List Item must be at least 4 characters")
                .test(
                  "notJustNumbers",
                  "List item cannot be just numbers",
                  notJustNumbers
                )
                .required("List item cannot be empty")
            ),
      }),
    });

    await schema.validate(parsedObj, { abortEarly: false });
  } catch (error: any) {
    if (error.inner) {
      console.log("===============================>error", error.inner);
      error.inner.forEach((err: any) => {
        let blockIndex: number;
        let blockId: string | undefined;

        if (err.path.startsWith("paragraphs")) {
          blockIndex = parseInt(err.path.split(/\[|\]/).filter(Boolean)[1]);
          blockId = objectToValidate.find(
            (item, index) => item.type === "paragraph" && index === blockIndex
          )?.id;
        } else if (err.path.startsWith("lists")) {
          blockIndex = parseInt(err.path.split(/\[|\]/).filter(Boolean)[1]);
          blockId = objectToValidate.find(
            (item, index) => item.type === "list" && index === blockIndex
          )?.id;
        }

        result.errors.push({
          blockId,
          path: err.path,
          message: err.message,
        });
      });
    }
    result.success = false;
  }
  return result;
}

export function validateContents(moduleRes) {
  for (const topicId in moduleRes.topics) {
    const topic = moduleRes.topics[topicId];
    if (!topic.contents || Object.keys(topic.contents).length === 0) {
      return false;
    }

    // Check each lesson
    for (const lessonId in topic.lessons) {
      const lesson = topic.lessons[lessonId];
      if (!lesson.contents || Object.keys(lesson.contents).length === 0) {
        return false;
      }

      // Check each point
      for (const pointId in lesson.points) {
        const point = lesson.points[pointId];
        if (!point.contents || Object.keys(point.contents).length === 0) {
          return false;
        }

        // Check each sub-point
        for (const subPointId in point.sub_points) {
          const subPoint = point.sub_points[subPointId];
          if (
            !subPoint.contents ||
            Object.keys(subPoint.contents).length === 0
          ) {
            return false;
          }
        }
      }
    }
  }
  // All objects have contents
  return true;
}
