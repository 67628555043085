import { PayloadAction } from "@reduxjs/toolkit";
import routes from "app/API/api.routes";
import makeCall from "app/API/makeCalls";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  clearLocalStorage,
  getFromLocalStorage,
  removeAndSetPrefLanguage,
  removeAndSetTokenExpiration,
  setToLocalStorage,
} from "utils/localStorage";
import { IForgetPassword, ISignInFormValues } from "app/pages/SignInPage/types";
import { defaultLayoutActions as actions } from ".";
import { DEFAULT_DESCRIPTION, DEFAULT_TITLE, VARIANTS } from "../constants";
import { TypeOptions } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { ISignInUserResponse, IUserAccountRes } from "./type";
import { defaultLayoutActions } from "app/pages/DefaultLayout/slice";
import { selectLoginModalOpen } from "./selectors";
import { decodeToken, localstorageGetItem } from "helper/helperFunctions";
import { ILanguage } from "utils/type";

export function* handleAPIError(error: any) {
  try {
    const errRes = JSON.parse(error.message);
    if (errRes.status === 401) {
      yield call(clearLocalStorage);
      yield put(actions.clearUser());
      yield put(actions.setIsLoginModalOpen(true));
    }
  } catch (parseError) {
    console.error("Error parsing the error message:", parseError);
  }
}

export function* handleUserPersist(user?: { token: string; email: string }) {
  const _user = yield call(getFromLocalStorage);
  if (!_user) {
    yield call(
      setToLocalStorage,
      JSON.stringify({
        token: user?.token,
        email: user?.email,
      })
    );
  }
  const exp = yield call(decodeToken, user?.token);
  yield call(removeAndSetTokenExpiration, exp);
}

export function* handlePreLanguagePersist(pref_language: string) {
  yield call(removeAndSetPrefLanguage, pref_language.toUpperCase());
}

function* signInUserSaga(action: PayloadAction<ISignInFormValues>) {
  try {
    const formData = new FormData();
    formData.append("username", action.payload.email || "");
    formData.append("password", action.payload.password || "");
    const client: ISignInUserResponse = yield call(makeCall, {
      isSecureRoute: false,
      method: "POST",
      route: routes.user.login,
      body: formData,
    });
    if (client?.code == "0") {
      yield put(actions.setIsLoginError(false));
      const isLoginOpen = yield select(selectLoginModalOpen);
      yield call(handleUserPersist, {
        token: client.access_token,
        email: client.username,
      });
      yield call(handlePreLanguagePersist, client.pref_language);
      yield put(
        actions.setUser({
          first_name: client?.firstname,
          last_name: client?.lastname,
          role: client?.role || "new",
          id: client?.username,
          phone: client?.phone,
          org_name: client?.organization,
          pref_language: client.pref_language.toUpperCase() as ILanguage,
          active_flag: true,
        })
      );
      yield put(
        actions.setGlobalLanguage(
          client.pref_language.toUpperCase() as ILanguage
        )
      );
      if (isLoginOpen) yield put(actions.setIsLoginModalOpen(false));
    } else {
      yield put(actions.setIsSigningIn(false));
      yield put(actions.setIsLoginError(true));
      if (client.message == "Invalid username or password") {
        yield put(
          actions.setAppMessage({
            currentMessage: "Invalid Credentials!",
            currentTitle: DEFAULT_TITLE.Fail,
            currentVariant: VARIANTS.Error as TypeOptions,
            count: uuidv4(),
          })
        );
      }
    }
  } catch (error: any) {
    console.log("==========================>", error);
    yield put(actions.setIsSigningIn(false));
    yield put(actions.setIsLoginError(true));
    yield put(
      actions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidv4(),
      })
    );
    yield call(handleAPIError, error);
  }
}

function* getUserAccountSaga() {
  try {
    const user: IUserAccountRes = yield call(makeCall, {
      isSecureRoute: true,
      method: "GET",
      route: routes.user.getAccount,
    });

    if (user?.code == "0") {
      const persistedUser = yield call(localstorageGetItem, "token");
      const persistedUserLanguage = localStorage.getItem(
        "pref_language"
      ) as ILanguage;
      yield call(handleUserPersist, {
        token: persistedUser,
        email: user.username,
      });
      yield call(
        handlePreLanguagePersist,
        persistedUserLanguage || user.pref_language
      );

      const orgs =
        user.role == "viewer" || user.role == "new" ? [] : user.organizations;

      yield put(
        actions.setUser({
          first_name: user?.first_name,
          last_name: user?.last_name,
          role: user?.role || "new",
          pref_language:
            persistedUserLanguage ||
            (user.pref_language.toUpperCase() as ILanguage),
          id: user?.username,
          phone: user?.phone,
          org_name: user.org_name,
          org_id: user.org_id,
          organizations: orgs,
          active_flag: user.active_flag,
        })
      );

      yield put(
        actions.setGlobalLanguage(
          persistedUserLanguage ||
            (user.pref_language.toUpperCase() as ILanguage)
        )
      );

      yield put(actions.setIsSigningIn(false));
    } else {
      throw new Error();
    }
  } catch (error: any) {
    yield put(actions.setIsSigningIn(false));
    yield call(handleAPIError, error);
  }
}

function* forgetPasswordSaga(action: PayloadAction<IForgetPassword>) {
  try {
    const { email } = action.payload;
    const response: any = yield call(makeCall, {
      method: "POST",
      isSecureRoute: false,
      route: routes.user.forgetPassword,
      body: { email },
    });
    if (response) {
      yield put(actions.setIsForgetPasswordLoading(false));
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage: DEFAULT_DESCRIPTION.Success,
          currentTitle: DEFAULT_TITLE.Success,
          currentVariant: VARIANTS.SUCCESS as TypeOptions,
          count: uuidv4(),
        })
      );
    }
  } catch (error: any) {
    yield put(actions.setIsForgetPasswordLoading(false));
    yield call(handleAPIError, error);

    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidv4(),
      })
    );
  }
}

function* setGlobalLanguageSaga(action: PayloadAction<ILanguage>) {
  yield call(handlePreLanguagePersist, action.payload);
}

export function* defaultLayoutSaga() {
  yield takeLatest(actions.signinUser.type, signInUserSaga);
  yield takeLatest(actions.getUserFromToken.type, getUserAccountSaga);
  yield takeLatest(actions.forgetPassword.type, forgetPasswordSaga);
  yield takeLatest(actions.setGlobalLanguage.type, setGlobalLanguageSaga);
}

export function* rootSaga() {
  yield all([defaultLayoutSaga()]);
  // code after all-effect
}
