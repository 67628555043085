import { memo, useEffect, useRef } from "react";
import EditorJS, {
  API,
  BlockMutationEvent,
  OutputData,
} from "@kalebu2468/editorjs";
import { EDITOR_JS_TOOLS } from "utils/tool";
import Undo from "@kalebu2468/editorjs-undo";

type Props = {
  data?: OutputData;
  onChange(
    index: number,
    api: API,
    event: BlockMutationEvent | BlockMutationEvent[]
  ): void;
  holder?: string;
  index: number;
  editorData?: string;
  handleInitialize: (index: number, instance: EditorJS, undo: any) => void;
};

const Editor = ({
  data,
  editorData,
  onChange,
  holder,
  index,
  handleInitialize,
}: Props) => {
  const undoRef = useRef<null>(null);
  useEffect(() => {
    const editor = new EditorJS({
      holder: holder,
      tools: EDITOR_JS_TOOLS,
      data: data,
      async onChange(api, event) {
        onChange(index, api, event);
      },
      readOnly: false,
      onReady: () => {
        if (editor) {
          const undo = new Undo({ editor });
          undo.initialize(data);
          undoRef.current = undo;
          handleInitialize(index, editor, undo);
        }
      },
    });
  }, []);

  return <div id={holder}></div>;
};

export default memo(Editor);
