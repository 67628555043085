import { call, put, takeLatest, all, select } from "redux-saga/effects";
import makeCall from "app/API/makeCalls";
import routes from "app/API/api.routes";
import { settingActions as actions } from ".";
import { defaultLayoutActions } from "app/pages/DefaultLayout/slice";
import {
  DEFAULT_DESCRIPTION,
  DEFAULT_TITLE,
  VARIANTS,
} from "app/pages/DefaultLayout/constants";
import { TypeOptions } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { PayloadAction } from "@reduxjs/toolkit";
import { IProfileUpdate } from "../type";
import { handleAPIError } from "app/pages/DefaultLayout/slice/saga";
import { selectUser } from "app/pages/DefaultLayout/slice/selectors";
import { IUserModel } from "app/Model/user";
import { ILanguage } from "utils/type";

function* inviteUser({
  new_user_email,
  new_user_role,
}: {
  new_user_email: string[];
  new_user_role: string;
}) {
  try {
    const response = yield call(makeCall, {
      method: "POST",
      isSecureRoute: true,
      route: routes.user.inviteUser,
      body: {
        new_user_email,
        new_user_role: new_user_role.toLowerCase(),
      },
    });
    if (response.code !== "0") throw new Error("Invalid status");
  } catch (err) {
    throw new Error("Invalid status");
  }
}

function* updateOrganizationSaga(action: PayloadAction<any>) {
  try {
    const response = yield call(makeCall, {
      method: "POST",
      isSecureRoute: true,
      route: routes.organizations.update,
      body: { ...action.payload, address: "" },
    });

    if (response) {
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage: DEFAULT_DESCRIPTION.Success,
          currentTitle: DEFAULT_TITLE.Success,
          currentVariant: VARIANTS.SUCCESS as TypeOptions,
          count: uuidv4(),
        })
      );
      yield put(actions.setIsUpdating(false));
    } else {
      throw new Error(response.message || "Failed to update organization");
    }
  } catch (error) {
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidv4(),
      })
    );
    yield put(actions.setIsUpdating(false));
    yield call(handleAPIError, error);
  }
}

function* updateUserSaga(action: PayloadAction<IProfileUpdate>) {
  try {
    const { is_admin, ...rest } = action.payload;
    const response = yield call(makeCall, {
      method: "PUT",
      isSecureRoute: true,
      route: `${routes.user.update}/${rest.email}`,
      body: {
        first_name: rest.first_name,
        last_name: rest.last_name,
        phone: rest.phone,
        active_flag: rest.active_flag,
        role: rest.role.toLowerCase(),
        pref_language: rest.pref_language,
        address: rest?.address,
      },
    });
    if (response.code == "0") {
      if (is_admin) {
        yield put(actions.listUsers());
      } else {
        yield put(
          defaultLayoutActions.setUser({
            first_name: rest.first_name,
            last_name: rest.last_name,
            id: rest.email,
            phone: rest.phone,
            pref_language: ILanguage.en,
            role: rest.role.toLowerCase(),
            active_flag: rest.active_flag,
            image_url: "",
            address: rest?.address,
          })
        );
      }
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage: DEFAULT_DESCRIPTION.Success,
          currentTitle: DEFAULT_TITLE.Success,
          currentVariant: VARIANTS.SUCCESS as TypeOptions,
          count: uuidv4(),
        })
      );
      yield put(actions.setIsUpdating(false));
    }
  } catch (error: any) {
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidv4(),
      })
    );
    yield put(actions.setIsUpdating(false));
    yield call(handleAPIError, error);
  }
}

function* getRolesSaga() {
  try {
    const response = yield call(makeCall, {
      method: "GET",
      isSecureRoute: true,
      route: routes.roles.list,
    });
    if (response.code == "0") {
      if (response?.Roles) {
        const res = response?.Roles?.reduce((acc: any, curr: any) => {
          acc.push({ id: curr.RoleId, name: curr.RoleName });
          return acc;
        }, []);
        yield put(actions.setRoles(res));
      }
      yield put(actions.setIsUpdating(false));
    }
  } catch (error: any) {
    yield put(actions.setIsUpdating(false));
    yield call(handleAPIError, error);
    console.log("Error Getting Modules", error);
  }
}

function* inviteUserSaga(
  action: PayloadAction<{ emails: string[]; role: string }>
) {
  try {
    const { emails, role } = action.payload;

    yield call(inviteUser, {
      new_user_email: emails,
      new_user_role: role,
    });

    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Success,
        currentTitle: DEFAULT_TITLE.Success,
        currentVariant: VARIANTS.SUCCESS as TypeOptions,
        count: uuidv4(),
      })
    );
    yield put(actions.setIsUpdating(false));
  } catch (error: any) {
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidv4(),
      })
    );
    yield put(actions.setIsUpdating(false));
    yield call(handleAPIError, error);
    console.log("Error inviting users", error);
  }
}

function* getUsersSaga() {
  try {
    const user = yield select(selectUser);
    const response = yield call(makeCall, {
      method: "GET",
      isSecureRoute: true,
      route: routes.user.list,
    });
    if (response.code == "0") {
      const users: IUserModel[] = [];
      // Add users from organizations
      response.organizations.forEach((org) => {
        org.users.forEach((user) => {
          users.push({
            ...user,
            id: user.username,
            org_name: org.org_name,
          });
        });
      });

      yield put(actions.setUsers(users));
      yield put(actions.setIsUpdating(false));
    }
  } catch (error: any) {
    yield put(actions.setIsUpdating(false));
    yield call(handleAPIError, error);
    console.log("Error Getting Modules", error);
  }
}

function* deleteUserSaga(action: PayloadAction<IUserModel>) {
  try {
    const response = yield call(makeCall, {
      method: "PUT",
      isSecureRoute: true,
      route: `${routes.user.update}/${action.payload.id}`,
      body: {
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        phone: action.payload.phone,
        role: action.payload.role,
        active_flag: false,
      },
    });
    if (response.code == "0") {
      yield put(actions.listUsers());
      yield put(
        defaultLayoutActions.setAppMessage({
          currentMessage: DEFAULT_DESCRIPTION.Success,
          currentTitle: DEFAULT_TITLE.Success,
          currentVariant: VARIANTS.SUCCESS as TypeOptions,
          count: uuidv4(),
        })
      );
      yield put(actions.setIsUpdating(false));
    }
  } catch (error: any) {
    yield put(
      defaultLayoutActions.setAppMessage({
        currentMessage: DEFAULT_DESCRIPTION.Error,
        currentTitle: DEFAULT_TITLE.Fail,
        currentVariant: VARIANTS.Error as TypeOptions,
        count: uuidv4(),
      })
    );
    yield put(actions.setIsUpdating(false));
    yield call(handleAPIError, error);
  }
}

export function* SettingSagaWatcher() {
  yield takeLatest(actions.updateUser.type, updateUserSaga);
  yield takeLatest(actions.getRoles.type, getRolesSaga);
  yield takeLatest(actions.inviteUser.type, inviteUserSaga);
  yield takeLatest(actions.listUsers.type, getUsersSaga);
  yield takeLatest(actions.deleteUser.type, deleteUserSaga);
  yield takeLatest(actions.updateOrganization.type, updateOrganizationSaga);
}
