import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from ".";
import { ModuleState } from "./type";

const selectSlice = (state: RootState) => state?.module || initialState;

export const selectModule: (state: RootState) => ModuleState = createSelector(
  [selectSlice],
  (state) => state
);

export const selectGettingModules = createSelector(
  [selectModule],
  (state) => state.isModuleLoading
);

export const selectDownloadingObject = createSelector(
  [selectModule],
  (state) => state.isDownloadingItem
);

export const selectChosenLanguage = createSelector(
  [selectModule],
  (state) => state.language
);

export const selectUploadState = createSelector(
  [selectModule],
  (state) => state.uploadState
);

export const selectSelectedFile = createSelector(
  [selectModule],
  (state) => state.selectedFile
);

export const selectInvalidBlocks = createSelector(
  [selectModule],
  (state) => state.invalidBlocks
);
