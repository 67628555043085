import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Grid,
  Chip,
  Button,
  MenuItem,
  Paper,
  Container,
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableRow,
  TableBody,
  IconButton,
  Avatar,
  Typography,
  useTheme,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Tooltip,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  AccountCircle as AccountCircleIcon,
  People as PeopleIcon,
  Business as BusinessIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import { selectDefaultLayoutState } from "../DefaultLayout/slice/selectors";
import { useSettingSlice } from "./slice";
import { selectRoles, selectSetting } from "./slice/selector";
import { IProfileFormValues } from "./type";
import { createUserValidationSchema } from "app/components/Form/Validation/createUserFormValidation";
import { IUserModel } from "app/Model/user";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { LanguageSelect } from "app/components/Core/Input/Select";
import { CustomModal, Spacer } from "app/components/Core";
import profileValidation from "app/components/Form/Validation/profileValidation";
import { theme as styledTheme } from "styles/theme/themes";
import { organizationValidationSchema } from "app/components/Form/Validation/organizationValidation";
import { IUpdateOrganization } from "./slice/types";

export function Settings() {
  const defaultLayout = useSelector(selectDefaultLayoutState);
  const dispatch = useDispatch();
  const { actions } = useSettingSlice();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  useEffect(() => {
    setSelectedIndex(
      location.pathname.includes("users")
        ? 1
        : location.pathname.includes("organization")
        ? 2
        : 0
    );
    if (location.pathname.includes("users")) {
      dispatch(actions.listUsers());
    }
  }, [location.pathname, dispatch, actions]);

  const handleListItemClick = (index: number) => {
    setSelectedIndex(index);
    navigate(
      index === 0
        ? "/settings"
        : index === 1
        ? "/settings/users"
        : "/settings/organization"
    );
  };

  return (
    <Box sx={{ display: "flex", height: "calc(100vh - 64px)", width: "100%" }}>
      <Grid container>
        <Grid item xs={3} sx={{ borderRight: "1px solid #e0e0e0" }}>
          <List component="nav" aria-label="settings options">
            <ListItem
              button
              selected={selectedIndex === 0}
              onClick={() => handleListItemClick(0)}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "transparent",
                  "& .MuiListItemText-primary": {
                    color: theme.palette.primary.main,
                    fontWeight: "bold",
                  },
                },
              }}
            >
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Account" />
            </ListItem>
            {defaultLayout.user?.role !== "new" &&
              defaultLayout.user?.role !== "viewer" && (
                <>
                  <ListItem
                    button
                    selected={selectedIndex === 1}
                    onClick={() => handleListItemClick(1)}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "transparent",
                        "& .MuiListItemText-primary": {
                          color: theme.palette.primary.main,
                          fontWeight: "bold",
                        },
                      },
                    }}
                  >
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                  </ListItem>
                  <ListItem
                    button
                    selected={selectedIndex === 2}
                    onClick={() => handleListItemClick(2)}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "transparent",
                        "& .MuiListItemText-primary": {
                          color: theme.palette.primary.main,
                          fontWeight: "bold",
                        },
                      },
                    }}
                  >
                    <ListItemIcon>
                      <BusinessIcon />
                    </ListItemIcon>
                    <ListItemText primary="Organization" />
                  </ListItem>
                </>
              )}
          </List>
        </Grid>
        <Grid item xs={9} sx={{ p: 3 }}>
          <Routes>
            <Route path="" element={<SettingsProfile />} />
            <Route path="users" element={<SettingsUsers />} />
            <Route path="organization" element={<SettingsOrganization />} />
          </Routes>
        </Grid>
      </Grid>
    </Box>
  );
}

export function SettingsProfile() {
  const defaultLayout = useSelector(selectDefaultLayoutState);
  const settingState = useSelector(selectSetting);
  const dispatch = useDispatch();
  const { actions } = useSettingSlice();
  const [avatarSrc, setAvatarSrc] = React.useState("/broken-image.jpg");
  const theme = useTheme();
  const handleFileUpload = () => {
    // Implement file upload logic here
  };

  const initialValues: IProfileFormValues = {
    first_name: defaultLayout?.user?.first_name || "",
    last_name: defaultLayout?.user?.last_name || "",
    phone: defaultLayout?.user?.phone || "",
    email: defaultLayout?.user?.id || "",
    pref_language: defaultLayout?.user?.pref_language || "",
    address: defaultLayout.user?.address || "",
  };

  return (
    <Box>
      <Typography
        gutterBottom
        sx={{
          fontSize: "24px",
          fontWeight: 600,
          color: styledTheme.colors.primary.main,
          mb: 3,
        }}
      >
        Profile Details
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
        <Box sx={{ position: "relative", mr: 3 }}>
          <Avatar src={avatarSrc} sx={{ width: 100, height: 100 }} />
          <IconButton
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              backgroundColor: "rgba(0, 0, 0, 0.3)",
            }}
            onClick={handleFileUpload}
          >
            <CameraAltIcon />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          border: `1px solid ${theme.palette.grey[300]}`,
          borderRadius: theme.shape.borderRadius,
          padding: theme.spacing(4),
          marginTop: theme.spacing(3),
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={profileValidation}
          onSubmit={(values, { setSubmitting }) => {
            dispatch(
              actions.updateUser({
                ...values,
                active_flag: true,
                role: defaultLayout.user?.role || "new",
                is_admin: false,
              })
            );
            setSubmitting(false);
          }}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="first_name"
                    label="First Name"
                    error={touched.first_name && errors.first_name}
                    helperText={touched.first_name && errors.first_name}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="last_name"
                    label="Last Name"
                    error={touched.last_name && errors.last_name}
                    helperText={touched.last_name && errors.last_name}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="phone"
                    label="Phone Number"
                    error={touched.phone && errors.phone}
                    helperText={touched.phone && errors.phone}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="email"
                    label="Email"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="address"
                    label="Address"
                    error={touched.address && errors.address}
                    helperText={touched.address && errors.address}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LanguageSelect name="pref_language" />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || settingState.isSettingLoading}
                  >
                    Save Changes
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

export function SettingsUsers() {
  const settingState = useSelector(selectSetting);
  const defaultLayout = useSelector(selectDefaultLayoutState);
  const dispatch = useDispatch();
  const { actions } = useSettingSlice();
  const role = defaultLayout?.user?.role;
  const organization = defaultLayout?.user?.org_name;
  const [modalOpen, setModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState<IUserModel | null>(null);
  const roles = useSelector(selectRoles);
  const theme = useTheme();

  useEffect(() => {
    if (modalOpen) {
      dispatch(actions.getRoles());
    }
  }, [modalOpen, dispatch, actions]);

  const handleEditUser = (username: string) => {
    const listUsers = settingState.users;
    if (listUsers) {
      const filteredUser = listUsers.find((item) => item.id === username);
      setCurrentUser(filteredUser || null);
      setModalOpen(true);
    }
  };

  const handleDeleteUser = (username: string) => {
    const listUsers = settingState.users;
    if (listUsers) {
      const filteredUser = listUsers.find((item) => item.id === username);
      if (filteredUser) {
        dispatch(actions.deleteUser(filteredUser));
      }
    }
  };

  const handleModalClose = () => {
    setCurrentUser(null);
    setModalOpen(false);
  };

  const ModalContent = React.useMemo(() => {
    return (
      <div
        style={{
          width: 400,
          padding: 20,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <IconButton
          onClick={handleModalClose}
          style={{ alignSelf: "flex-end" }}
        >
          <CloseIcon />
        </IconButton>
        <h2>{currentUser ? "Edit User" : "Add Bulk Users"}</h2>
        <Formik
          initialValues={
            currentUser
              ? {
                  first_name: currentUser.first_name,
                  last_name: currentUser.last_name,
                  phone: currentUser.phone,
                  email: currentUser.id,
                  role: currentUser?.role
                    ? `${
                        currentUser.role.charAt(0).toUpperCase() +
                        currentUser.role.slice(1)
                      }`
                    : "",
                  pref_language: currentUser.pref_language.toUpperCase(),
                  address: currentUser.address,
                }
              : { emails: "", role: "" }
          }
          validationSchema={
            currentUser ? profileValidation : createUserValidationSchema
          }
          onSubmit={(values, { setSubmitting, resetForm }) => {
            console.log("Form submitted", values);
            if (currentUser) {
              const { first_name, last_name, role, address, phone, email } =
                values;
              if (first_name && last_name && role && address && phone && email)
                dispatch(
                  actions.updateUser({
                    ...values,
                    active_flag: true,
                    is_admin: true,
                  })
                );
            } else {
              const emails = values?.emails
                ?.split(",")
                .map((email) => email.trim());
              if (emails)
                dispatch(actions.inviteUser({ emails, role: values.role }));
            }
            setSubmitting(false);
            resetForm();
            handleModalClose();
          }}
        >
          {({ errors, touched, isSubmitting, handleSubmit }) => (
            <Form
              onSubmit={handleSubmit}
              style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
            >
              {currentUser ? (
                <div>
                  <Field
                    name="first_name"
                    as={TextField}
                    label="First Name"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={touched.first_name && errors.first_name}
                  />
                  <Field
                    name="last_name"
                    as={TextField}
                    label="Last Name"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={touched.last_name && errors.last_name}
                  />
                  <Field
                    name="phone"
                    as={TextField}
                    label="Phone"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={touched.phone && errors.phone}
                  />
                  <Field
                    name="email"
                    as={TextField}
                    label="Email"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    disabled
                    error={touched.email && errors.email}
                  />
                  <Field
                    name="role"
                    as={TextField}
                    select
                    label="Role"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={touched.role && errors.role}
                  >
                    {roles.map((role) => (
                      <MenuItem
                        key={role.id}
                        value={`${
                          role.name.charAt(0).toUpperCase() + role.name.slice(1)
                        }`}
                      >
                        {`${
                          role.name.charAt(0).toUpperCase() + role.name.slice(1)
                        }`}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    name="address"
                    as={TextField}
                    label="Address"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={touched.address && errors.address}
                  />
                  <Spacer mb={2} />
                  <LanguageSelect name="pref_language" />
                  <Spacer mb={4} />
                </div>
              ) : (
                <>
                  <Field
                    name="emails"
                    as={TextField}
                    label="Emails (comma-separated)"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows={4}
                    error={touched.emails && errors.emails}
                    helperText={touched.emails && errors.emails}
                  />
                  <Field
                    name="role"
                    as={TextField}
                    select
                    label="Role"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={touched.role && errors.role}
                    helperText={touched.role && errors.role}
                  >
                    {roles.map((role) => (
                      <MenuItem key={role.id} value={role.name}>
                        {role.name}
                      </MenuItem>
                    ))}
                  </Field>
                </>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || role === "new"}
                style={{ marginTop: "auto" }}
              >
                {currentUser ? "Update" : "Submit"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }, [currentUser, roles, dispatch, actions, handleModalClose]);
  return (
    <Container
      sx={{
        "&.MuiContainer-root": {
          maxWidth: "unset",
        },
      }}
    >
      <CustomModal open={modalOpen} handleClose={handleModalClose}>
        {ModalContent}
      </CustomModal>

      <Paper>
        <Box
          sx={{
            p: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Org. Name: {organization}</Typography>
          {(role === "admin" || role === "super_user") && (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setModalOpen(true)}
            >
              Add User
            </Button>
          )}
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: theme.palette.grey[100],
                  borderBottom: `2px solid ${theme.palette.divider}`,
                }}
              >
                <TableCell
                  sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                >
                  First Name
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                >
                  Last Name
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                >
                  Phone
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                >
                  Address
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                >
                  Pref Language
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                >
                  Role
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                >
                  Is Active
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {settingState.users?.map((user, index) => (
                <TableRow
                  key={user.id}
                  sx={{
                    "&:nth-of-type(even)": {
                      backgroundColor: theme.palette.action.hover,
                    },
                    borderBottom: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <TableCell>{user.first_name}</TableCell>
                  <TableCell>{user.last_name}</TableCell>
                  <TableCell>{user.id}</TableCell>
                  <TableCell>{user.phone}</TableCell>
                  <TableCell>{user.address}</TableCell>
                  <TableCell>{user.pref_language}</TableCell>
                  <TableCell>{user.role}</TableCell>
                  <TableCell>
                    <Chip
                      label={user.active_flag ? "Active" : "Inactive"}
                      color={user.active_flag ? "success" : "error"}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleEditUser(user.id!)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDeleteUser(user.id!)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
}

export function SettingsOrganization() {
  const dispatch = useDispatch();
  const { actions } = useSettingSlice();
  const defaultLayout = useSelector(selectDefaultLayoutState);
  const settingState = useSelector(selectSetting);
  const orgDetails = defaultLayout.user?.organizations?.[0];
  const [showSecrets, setShowSecrets] = useState(false);

  const toggleSecretVisibility = () => {
    setShowSecrets(!showSecrets);
  };

  const initialValues = {
    id: defaultLayout.user?.org_id || "",
    name: orgDetails?.org_name || "",
    email: orgDetails?.org_email || "",
    is_active: orgDetails?.is_org_active || false,
    aws_access_key: orgDetails?.org_aws_access_key || "",
    aws_secret_key: orgDetails?.org_aws_secret_key || "",
    ses_region: orgDetails?.org_ses_region || "",
    ses_username: orgDetails?.org_ses_username || "",
    ses_password: orgDetails?.org_ses_password || "",
    openai_api_key: orgDetails?.org_openai_apikey || "",
    coudinary_cloud_name: orgDetails?.org_cloudinary_name || "",
    cloudinary_api_key: orgDetails?.org_cloudinary_api_key || "",
    cloudinary_api_secret: orgDetails?.org_cloudinary_api_secret || "",
    deepl_api_key: orgDetails?.org_deepl_api_key || "",
  };

  const handleSubmit = (values: IUpdateOrganization, { setSubmitting }) => {
    dispatch(actions.updateOrganization(values));
    setSubmitting(false);
  };

  return (
    <Box>
      <Typography
        gutterBottom
        sx={{
          fontSize: "24px",
          fontWeight: 600,
          color: styledTheme.colors.primary.main,
          mb: 3,
        }}
      >
        Organization Details
      </Typography>
      <Paper
        sx={{
          p: 4,
          mt: 3,
          maxHeight: "calc(100vh - 200px)",
          overflow: "auto",
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={organizationValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="name"
                    label="Organization Name"
                    error={touched.name && errors.name}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="email"
                    label="Organization Email"
                    error={touched.email && errors.email}
                    helperText={touched.email && errors.email}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ my: 3 }} />
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    AWS Configuration
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="aws_access_key"
                    label="AWS Access Key"
                    type={showSecrets ? "text" : "password"}
                    error={touched.aws_access_key && errors.aws_access_key}
                    helperText={touched.aws_access_key && errors.aws_access_key}
                    InputProps={{
                      endAdornment: (
                        <Tooltip title={showSecrets ? "Hide" : "Show"}>
                          <IconButton onClick={toggleSecretVisibility}>
                            {showSecrets ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="aws_secret_key"
                    label="AWS Secret Key"
                    type={showSecrets ? "text" : "password"}
                    error={touched.aws_secret_key && errors.aws_secret_key}
                    helperText={touched.aws_secret_key && errors.aws_secret_key}
                    InputProps={{
                      endAdornment: (
                        <Tooltip title={showSecrets ? "Hide" : "Show"}>
                          <IconButton onClick={toggleSecretVisibility}>
                            {showSecrets ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ my: 3 }} />
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    SES Configuration
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="ses_region"
                    label="SES Region"
                    error={touched.ses_region && errors.ses_region}
                    helperText={touched.ses_region && errors.ses_region}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="ses_username"
                    label="SES Username"
                    error={touched.ses_username && errors.ses_username}
                    helperText={touched.ses_username && errors.ses_username}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="ses_password"
                    label="SES Password"
                    type={showSecrets ? "text" : "password"}
                    error={touched.ses_password && errors.ses_password}
                    helperText={touched.ses_password && errors.ses_password}
                    InputProps={{
                      endAdornment: (
                        <Tooltip title={showSecrets ? "Hide" : "Show"}>
                          <IconButton onClick={toggleSecretVisibility}>
                            {showSecrets ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ my: 3 }} />
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    API Keys
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="openai_api_key"
                    label="OpenAI API Key"
                    type={showSecrets ? "text" : "password"}
                    error={touched.openai_api_key && errors.openai_api_key}
                    helperText={touched.openai_api_key && errors.openai_api_key}
                    InputProps={{
                      endAdornment: (
                        <Tooltip title={showSecrets ? "Hide" : "Show"}>
                          <IconButton onClick={toggleSecretVisibility}>
                            {showSecrets ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="deepl_api_key"
                    label="DeepL API Key"
                    type={showSecrets ? "text" : "password"}
                    error={touched.deepl_api_key && errors.deepl_api_key}
                    helperText={touched.deepl_api_key && errors.deepl_api_key}
                    InputProps={{
                      endAdornment: (
                        <Tooltip title={showSecrets ? "Hide" : "Show"}>
                          <IconButton onClick={toggleSecretVisibility}>
                            {showSecrets ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ my: 3 }} />
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Cloudinary Configuration
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="coudinary_cloud_name"
                    label="Cloudinary Cloud Name"
                    error={
                      touched.coudinary_cloud_name &&
                      errors.coudinary_cloud_name
                    }
                    helperText={
                      touched.coudinary_cloud_name &&
                      errors.coudinary_cloud_name
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="cloudinary_api_key"
                    label="Cloudinary API Key"
                    type={showSecrets ? "text" : "password"}
                    error={
                      touched.cloudinary_api_key && errors.cloudinary_api_key
                    }
                    helperText={
                      touched.cloudinary_api_key && errors.cloudinary_api_key
                    }
                    InputProps={{
                      endAdornment: (
                        <Tooltip title={showSecrets ? "Hide" : "Show"}>
                          <IconButton onClick={toggleSecretVisibility}>
                            {showSecrets ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="cloudinary_api_secret"
                    label="Cloudinary API Secret"
                    type={showSecrets ? "text" : "password"}
                    error={
                      touched.cloudinary_api_secret &&
                      errors.cloudinary_api_secret
                    }
                    helperText={
                      touched.cloudinary_api_secret &&
                      errors.cloudinary_api_secret
                    }
                    InputProps={{
                      endAdornment: (
                        <Tooltip title={showSecrets ? "Hide" : "Show"}>
                          <IconButton onClick={toggleSecretVisibility}>
                            {showSecrets ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={settingState.isSettingLoading}
                >
                  Update Organization
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
}
