import * as React from "react";
import {
  Toolbar,
  styled,
  Box as MuiBox,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { Box, Spacer } from "app/components/Core";
import MuiAppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import { AppBarProps, IAppBar } from "./types";
import SaveIcon from "@mui/icons-material/Save";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DoneIcon from "@mui/icons-material/Done";
import { useCourseSlice } from "app/pages/Courses/slice";
import { useDispatch } from "react-redux";

import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => {
    return prop !== "isPushedLeft";
  },
})<AppBarProps>(({ theme, open }) => ({
  height: 63,
  ...(open
    ? {
        marginLeft: `calc(${drawerWidth}px - 30px)`,
        width: `calc(100% - (${drawerWidth}px-30px))`,
      }
    : {
        marginLeft: 0,
        width: "100%",
      }),
}));

export default function AppBarLayout({
  value,
  open,
  handleContentSubmit,
  isSaveDisabled,
  handleTranslate,
  languageOpen,
  handleLanguageOpen,
  handleLanguageClose,
  role,
  languageOptions,
  handleRedo,
  handleUndo,
}: IAppBar) {
  const navigate = useNavigate();
  const isLanguageOpen = Boolean(languageOpen);
  const { actions } = useCourseSlice();
  const dispatch = useDispatch();

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  ) => {
    setAnchorEl(null);
  };

  return (
    <AppBar sx={{ width: "100%", margin: 0 }} position="sticky" open={open}>
      <Toolbar>
        <Button
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={() => {
            dispatch(actions.clearModule());
            navigate("/courses");
          }}
        >
          go back
        </Button>
        <MuiBox sx={{ flexGrow: 1 }} />

        <Box
          flexGrow={0}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <IconButton size="small" onClick={handleUndo}>
            <UndoIcon />
          </IconButton>
          <IconButton size="small" onClick={handleRedo}>
            <RedoIcon />
          </IconButton>
          <Spacer mr={2} />

          {(role == "admin" || role == "editor" || role == "super_user") && (
            <>
              <Button
                onClick={handleLanguageOpen}
                endIcon={isLanguageOpen ? <ExpandLess /> : <ExpandMore />}
              >
                Translate
              </Button>
              <Spacer mr={3} />
              <Menu
                id="basic-menu"
                anchorEl={languageOpen}
                open={isLanguageOpen}
                onClose={handleLanguageClose}
              >
                {languageOptions.map((language) => (
                  <MenuItem
                    key={language.value}
                    onClick={() => handleTranslate(language.value)}
                  >
                    <MuiBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                      }}
                    >
                      <MuiBox>{language.label}</MuiBox>
                      <MuiBox sx={{ mb: "2px" }}>
                        {language.value == value && (
                          <DoneIcon fontSize="small" />
                        )}
                      </MuiBox>
                    </MuiBox>
                  </MenuItem>
                ))}
              </Menu>
              <Spacer mr={3} />
              <Button
                startIcon={<SaveIcon />}
                variant="contained"
                onClick={handleContentSubmit}
                disabled={isSaveDisabled}
              >
                save
              </Button>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
